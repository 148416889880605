import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Spinner } from 'react-bootstrap';

function App() {
  const [joke, setJoke] = useState({ id: '', opener: '', punchline: '', laughCount: 0, noLaughCount: 0, shownCount: 0 });
  const [loading, setLoading] = useState(false);
  const [selectedDeck, setSelectedDeck] = useState('');

  const fetchJoke = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://pun-deck-game-qqtwbbgeaq-ue.a.run.app/next-joke?collection=${selectedDeck}`); // Use your Cloud Run URL
      const data = await response.json();
      setJoke({ id: data.id, opener: data.opener, punchline: data.punchline, laughCount: data.laughCount || 0, noLaughCount: data.noLaughCount || 0, shownCount: data.shownCount || 0 });
    } catch (error) {
      console.error('Error fetching the joke:', error);
    }
    setLoading(false);
  };

  const rateJoke = async (rating) => {
    try {
      await fetch('https://pun-deck-game-qqtwbbgeaq-ue.a.run.app/rate-joke', { // Use your Cloud Run URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ jokeId: joke.id, rating, collection: selectedDeck })
      });
      fetchJoke();
    } catch (error) {
      console.error('Error rating the joke:', error);
    }
  };

  useEffect(() => {
    if (selectedDeck) {
      fetchJoke();
    }
  }, [selectedDeck]);

  return (
    <div className="App">
      <header className="App-header">
        {!selectedDeck ? (
          <div>
            <h2>Choose a Deck</h2>
            <button className="btn btn-primary m-2" onClick={() => setSelectedDeck('Dad Jokes')}>Dad Jokes</button>
            <button className="btn btn-primary m-2" onClick={() => setSelectedDeck('Adult Jokes')}>Adult Jokes</button>
          </div>
        ) : (
          <div className="card">
            <h2>{loading ? <Spinner animation="border" /> : joke.opener}</h2>
            <p>{!loading && joke.punchline}</p>
            <div className="button-group">
              <button className="btn btn-danger" onClick={() => rateJoke('noLaugh')} disabled={loading}>
                Did Not Laugh
              </button>
              <button className="btn btn-success" onClick={() => rateJoke('laugh')} disabled={loading}>
                Laughter
              </button>
            </div>
          </div>
        )}
        {selectedDeck && (
          <div className="stats">
            <p>Did Not Laugh: {joke.noLaughCount} - Laughs: {joke.laughCount} - Total Views: {joke.shownCount}</p>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;