import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCBKMR_Zafu7PHkIRiNee_1nPFAkAqHpMU",
  authDomain: "pundeck.firebaseapp.com",
  projectId: "pundeck",
  storageBucket: "pundeck.appspot.com",
  messagingSenderId: "272893229455",
  appId: "1:272893229455:web:90ec38937c5c611915b3fa",
  measurementId: "G-WQBEPGNTQN"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);